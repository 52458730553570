<!--
 * @Descripttion: 进行的项目
 * @version: 1.0
 * @Author: Niklaus
 * @Date: 2021-08-26 13:59:33
-->
<template>
    <el-main>
      <h1 class="base-title">进行的项目</h1>
      <div class="search mt">
        <el-input  placeholder="请输入内容"  v-model="searchVal"  clearable :input-style="inputStyle" @clear="onProjectList"></el-input>
        <el-button type="primary" @click="onProjectList">搜索</el-button>
      </div>

      <el-table :data="tableData" style="width: 100%">
        <el-table-column label="#" type="index"  min-width="5%">
        </el-table-column>
        <el-table-column label="倒计时" min-width="10%">
          <template #default="scope">
            <span class="el-link el-link--danger">{{ scope.row.ttt }}</span>
          </template>
        </el-table-column>
        <el-table-column label="状态"  min-width="5%">
          <template #default="scope">
            <el-tag type="success" size="normal" v-if="scope.row.processDetailStatus == '11'">待报价</el-tag>
            <el-tag type="" size="normal" v-else-if="scope.row.processDetailStatus == '12'">报价中</el-tag>
            <el-tag type="warning" size="normal" v-else-if="scope.row.processDetailStatus == '13'">待确认</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="项目编号" min-width="10%">
          <template #default="scope">
            <span >{{ scope.row.projectNo }}</span>
          </template>
        </el-table-column>
        <el-table-column label="项目名称" min-width="20%">
          <template #default="scope">
            <span >{{ scope.row.projectName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="项目类型" min-width="5%">
          <template #default="scope">
            <span >{{ scope.row.typeName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="是否参加" min-width="5%">
          <template #default="scope">
            <span v-if="scope.row.partakeStatus == '0'" class="el-link el-link--info">待确认</span>
            <span v-else-if="scope.row.partakeStatus == '1'" class="el-link el-link--primary">参加</span>
            <span v-else-if="scope.row.partakeStatus == '2'" class="el-link el-link--danger">不参加</span>
            <span v-else class="el-link el-link--warning">待确认</span>
          </template>
        </el-table-column>
        <el-table-column label="竞价开始时间" min-width="10%">
          <template #default="scope">
            <span>{{ scope.row.biddBeginDatetime }}</span>
          </template>
        </el-table-column>
        <el-table-column label="竞价结束时间" min-width="10%">
          <template #default="scope">
            <span>{{ scope.row.biddEndDatetime }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" min-width="20%">
          <template #default="scope">
            <el-button size="mini" type="success" @click="handleBiddingNotice(scope.$index, scope.row)">竞价邀请</el-button>
            <el-button v-show="scope.row.partakeStatus =='1'"  size="mini" type="primary" @click="handleGo(scope.$index, scope.row)">竞价厅</el-button>
            <el-button v-show="scope.row.partakeStatus =='0'" size="mini" type="danger" @click="onShowConfirmJoin(scope.row)">确认是否参加</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block page-pagination">
        <el-pagination
          prev-text="上一页"
          next-text="下一页"
          layout="prev, pager, next"
          @current-change="goPage"
          :total="total">
        </el-pagination>
      </div>

      <el-dialog
      :visible.sync="showConfirm"
      width="500px"
      center>
      <template #title>
        <h1 class="base-title">确认是否参加</h1>
      </template>
      <el-radio-group v-model="confirmItemJoin" class="tempRadio">
        <el-radio label="1">参加</el-radio>
        <el-radio label="2">不参加</el-radio>
      </el-radio-group>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="showConfirm= false" size="mini" type="info">取消</el-button>
        <el-button type="primary" @click="doConfirmJoin" size="mini" >确定</el-button>
        </span>
      </template>
    </el-dialog>


    </el-main>
</template>

<script>
import {httpPost,httpGet,httpPut} from '@/utils/http'
import global from '@/common/global';
import { dateFormat, countDown } from "@/utils/date";

export default {
  name: 'OnGoing',
  data(){
    return {
      searchVal: '',
      inputStyle:{width:'300px'},
      tableData: [],
      joinItemIndex:'',
      showConfirm:false,
      confirmItemJoin:'1',
      timeInterval :'',
      queryParam: {
          supplierStoreId: "",
          partakeStatus:"",
          projectName: "",
          projectNo: "",
          processStatus: "10",
          processDetailStatus: ""
      },
      pageParams:{
          pageNo:1,
          pageSize:10
      },
      urls:{
        bidingBulletin: '/ewbid/bulletin/tbEwProjectBulletin/getProjectBiddingNotice',
        detailProjectList: "/ewbid/bidd/supplierProjectController/list",
        simpleProjectList: "/ewbid/bidd/supplierProjectController/simpleDataList",
        cofirmJoinProject: "/ewbid/bidd/tbEwProjectSupplierMap/doCofirmJoinProject",
        serviceTime: "/ewbid/bidding/tbEwProjectSupplierOfferRecords/getServiceTime",
      },
      wantJoinProject:{},
      serviceTime: "",
      total: 0,

    }
  },

  created(){
    this.queryParam.supplierStoreId = this.$ls.get("storeInfo").id
    this.onProjectList();
    this.getServiceTime();
  },

  methods: {
    /**
     * @Description: 查看项目竞价公告
     * @Author: Niklaus
     * @Date: 2021-09-05 15:32:54
     */
    handleBiddingNotice(index, row) {
      let data  = {
        projectId: row.id,
        bulletinType: '01',
        releaseStatus: '01',
        auditStatus: '01'
      }
      httpGet(global.gateway + this.urls.bidingBulletin,data).then(res =>{
        if(res.data.success)
          window.open(res.data.result.bulletinUrl)
        else
          this.$message.error("查询公告数据出错")
      })
    },

    handleGo(index,row){
      let params = {
        id : row.id,
        supplierStoreId : this.queryParam.supplierStoreId
      }
      httpGet(global.gateway+this.urls.detailProjectList,params).then(res=>{
        if(res.data.success&&res.data.result.records.length>0){
          this.$ls.set('currentProject',res.data.result.records[0]);
          if(row.quotationMethod == '5')
            this.$router.push("/bidQuotaHall")
          else
            this.$router.push('/bidHall')
        }else{
          this.$message({
            type: 'error',
            message: '数据查询失败，请稍后重试!'
          });
        }
      }).catch(() => {
        this.$message({
          type: 'error',
          message: '数据查询失败，请稍后重试'
        });
      });
    },
    handleDelete(index, row) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },

    //查询项目列表
    onProjectList(){
      this.queryParam.projectName = this.searchVal;
      let params = Object.assign({}, this.queryParam)
      params.pageNo = this.pageParams.pageNo;
      params.pageSize = this.pageParams.pageSize;
      httpGet(global.gateway+this.urls.simpleProjectList,params).then(res=>{
        this.tableData = res.data.result.records;
        this.total = res.data.result.total;
        // 创建定时器
        this.tableData.map(row =>{
          setInterval(() => {
            this.$set(row,'ttt', countDown(new Date(this.serviceTime),new Date(row.biddBeginDatetime)))
          }, 1000);
        });

      });
    },
    //
    onShowConfirmJoin(row){
      this.showConfirm=true;
      this.wantJoinProject = row;
    },
    //确认参加
    doConfirmJoin(){
      let params ={
        projectId : this.wantJoinProject.id,
        supplierStoreId : this.wantJoinProject.supplierStoreId,
        updateBy: this.$ls.get("userInfo").id,
        partakeStatus: this.confirmItemJoin
      }
      httpPost(global.gateway+this.urls.cofirmJoinProject,params).then(res=>{
        let msg = '';
        if(this.confirmItemJoin=='1'){
          msg = '确认参与成功';
        }else{
          msg = '确认不参与成功';
        }
        if(res.data.success){
          this.$message({
            type: 'success',
            message: msg
          });
          this.showConfirm=false;
          this.onProjectList();
        }else{
          this.$message({
            type: 'error',
            message: res.data.message,
            duration: 5000
          });
          this.showConfirm=false;
          this.onProjectList();
        }
      }).catch(()=>{
          this.$message({
            type: 'error',
            message: '确认失败，请稍后重试！'
          });
          this.showConfirm=false;
      });

    },
    /**
     * @Description: 获取服务器时间，并定时更新时间
     * @Author: Niklaus
     * @Date: 2021-09-16 16:46:50
     */
    getServiceTime(){
      httpGet(global.gateway + this.urls.serviceTime).then( res => {
        this.serviceTime = res.data.result
        /** 服务器时间 */
        this.serviceTimeInterval = setInterval(() => {
          let current = new Date(this.serviceTime)
          current.setSeconds(current.getSeconds() +1)
          this.serviceTime = dateFormat(current)
        }, 1000);
      })
    },
    //跳页
    goPage(currentPage){
        this.loading = true
        this.pageParams.pageNo = currentPage
        this.onProjectList()
    },

  },


}
</script>

<style lang="scss" scoped>
.el-container {
  background-color: #fff;
  height: 100%;
  .el-header {
    display: flex;
    display: -webkit-flex;
    display: -ms-flexbox;
    align-items: center;
    h1 {
      font-size: 23px;
      font-weight: bold;
      margin-right: 30px;
    }
  }
  .el-main {
    .search {
      display: flex;
      justify-content: flex-start;
      .el-input {
        width: 300px;
      }
    }
    .page-pagination {
      width: 100%;
      margin-top: 50px;
      display: flex;
      justify-content: center;
    }
  }
}

 .tempRadio{
    width: 100%;
    display: flex;
    margin-top: 15px;
    margin-bottom: 30px;
    .el-radio {
      flex: 1;
      text-align: center;
    }
  }
</style>